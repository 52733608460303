import React from 'react';
import { Nav, Tab } from 'react-bootstrap';
import classnames from 'classnames';
import { ContentContainer, Spinner, Icon, Paper } from '@webfx/core-web';
import { useAuth, useFlags, useDocumentTitle, useNutshellAuth } from '@webfx/web-hooks';
import { NutshellEmbed } from '@webfx/web-ui-connected';
import Profile from './Profile';
import Tokens from './Tokens';

import styles from './MyInfo.module.css';

const EmailNotifications = React.lazy(() => import('./EmailNotifications'));

const MyInfo = () => {
  const { user = {}, isAdmin } = useAuth();
  const flags = useFlags();
  const canViewNotifications = flags.iamEmailpreferences;
  useDocumentTitle('My Info | MarketingCloudFX');

  return (
    <ContentContainer>
      <Paper>
        <div className="d-flex">
          <Tab.Container id="view-nav" defaultActiveKey="profile">
            <aside className={classnames(['col-3', styles.sidebar])}>
              <Nav variant="pills" className="flex-column">
                <NavItem label="Profile" icon="person" eventKey="profile" />
                <NavItem label="API Keys" icon="vpn_key" eventKey="tokens" />
                {isAdmin && <NavItem label="SchedulerFX" icon="mood" eventKey="schedulerFx" />}
                {canViewNotifications && (
                  <NavItem label="Email Notifications" icon="email" eventKey="emailNotifications" />
                )}
              </Nav>
            </aside>
            <Tab.Content className="flex-fill overflow-auto pl-3">
              <Tab.Pane eventKey="profile">
                <Profile />
              </Tab.Pane>
              <Tab.Pane eventKey="tokens">
                <Tokens />
              </Tab.Pane>
              {isAdmin && (
                <Tab.Pane eventKey="schedulerFx">
                  <FXerNutshellEmbedframe />
                </Tab.Pane>
              )}
              {canViewNotifications && (
                <Tab.Pane eventKey="emailNotifications">
                  <EmailNotifications viewingUser={user} />
                </Tab.Pane>
              )}
            </Tab.Content>
          </Tab.Container>
        </div>
      </Paper>
    </ContentContainer>
  );
};

const FXerNutshellEmbedframe = () => {
  const embedUrl = 'https://nutshell.webfx.com/embed/scheduler';
  const auth = useNutshellAuth('', 'embed', '365724');
  const { token } = auth?.data || {};
  if (!token) {
    return (
      <div className="mt-5 d-flex justify-content-center">
        <Spinner />
      </div>
    );
  }
  return <NutshellEmbed token={token} embedURL={embedUrl} />;
};

const NavItem = ({ label, icon = '', eventKey, ...passThroughProps }) => {
  const [iconType] = icon.split('_outlined');
  const outlined = icon.endsWith('_outlined');
  return (
    <Nav.Item>
      {
        <Nav.Link
          className={styles.navLink}
          data-fx-name={eventKey}
          eventKey={eventKey}
          {...passThroughProps}
        >
          {iconType && <Icon type={iconType} outlined={!!outlined} className={styles.navIcon} />}
          <span>{label}</span>
        </Nav.Link>
      }
    </Nav.Item>
  );
};
export default MyInfo;
