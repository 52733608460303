'use strict';

const isNumeric = require('./is-numeric');
const groupByMulti = require('./group-by-multi');
const camelCaseKeys = require('./camel-case-keys');
const isPhoneNumber = require('./is-phone-number');
const isEmail = require('./is-email');
const sharedEmailRegex = require('./shared-email-regex');
const getMroiChannel = require('./get-mroi-channel');
const getContactStageOptions = require('./get-contact-stage-options');
const getTouchpointStatusOptions = require('./get-touchpoint-status-options');
const getTouchpointStatusOptionsSpanner = require('./get-touchpoint-status-options-spanner');
const escapeCharacters = require('./escape-characters');
const castObjectDeep = require('./cast-object-deep');
const isCypressOrTestsActive = require('./is-cypress-or-tests-active');

// plans utils
const getPlanIcon = require('./plans/get-plan-icon');
const getPlanLevers = require('./plans/get-plan-levers');
const getPlanMetadata = require('./plans/get-plan-metadata');
const getPlanBlockQty = require('./plans/get-plan-block-quantity');
const getPlanMarketingContactsConfig = require('./plans/get-plan-marketing-contacts-config');

// lead utils
const getLeadAddress = require('./leads/get-lead-address');
const getLeadCompanyName = require('./leads/get-lead-company-name');

module.exports = {
  camelCaseKeys,
  castObjectDeep,
  escapeCharacters,
  getPlanIcon,
  getPlanLevers,
  getMroiChannel,
  getPlanBlockQty,
  getPlanMetadata,
  getPlanMarketingContactsConfig,
  getContactStageOptions,
  getTouchpointStatusOptions,
  getTouchpointStatusOptionsSpanner,
  getLeadAddress,
  getLeadCompanyName,
  groupByMulti,
  isEmail,
  sharedEmailRegex,
  isNumeric,
  isPhoneNumber,
  isCypressOrTestsActive,
};
