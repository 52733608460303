/**
 * Retrieves the address from the given lead.
 * @param {*} lead - The lead object containing the company and organization names.
 * @returns - The retrieved lead address.
 */
function getLeadAddress(lead) {
  let address = null;

  if (!lead) {
    return address;
  }

  if (lead.entity === 'form' && lead.payload?.data?.mapped?.length) {
    address = addressFromPayload(lead.payload.data.mapped);
  }

  const companyAddress = lead.company?.addresses?.[0];
  if (!address && companyAddress) {
    address = addressString(companyAddress);
  }

  return address;
}

function addressFromPayload(mappedInputs) {
  const address = {};

  for (const input of mappedInputs) {
    const field = getAddressField(input.types);
    if (field) {
      address[field] = input.value;
    }
  }

  return Object.keys(address).length ? addressString(address) : null;
}

const addressFields = {
  streetAddress: 'street1',
  'address-street2': 'street2',
  'address-city': 'city',
  'address-state': 'stateRegion',
  postalCode: 'postal',
  'address-country': 'country',
};

function getAddressField(inputTypes) {
  for (const inputType of inputTypes) {
    const addressFieldKey = Object.keys(addressFields).find((key) => key === inputType);
    if (addressFieldKey) {
      return addressFields[addressFieldKey];
    }
  }
}

function addressString(address) {
  const { street1, street2, city, region, state, stateRegion, postal, zip, country } =
    address ?? {};
  const normalizedCity = city ? city.replace(/\b\w/g, (c) => c.toUpperCase()) : city;
  const normalizedState = state && state.length === 2 ? state.toUpperCase() : state;
  const normalizedCountry = country && country.length === 2 ? country.toUpperCase() : country;

  return [
    street1,
    street2,
    normalizedCity,
    region,
    normalizedState,
    stateRegion,
    postal,
    zip,
    normalizedCountry,
  ]
    .filter(Boolean)
    .join(', ');
}

module.exports = getLeadAddress;
